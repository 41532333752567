import React from "react";
import "./footer.css"

export default function Footer(props) {

  return (
    <footer className="main-footer">
      <span className="main-footer-text"> Enrique Gómez since 1995 with love. </span>
    </footer>
  )
}